import styled, { keyframes } from 'styled-components'
import Vector1 from 'assets/vectors/footer-vector1.svg'
import Vector2 from 'assets/vectors/footer-vector2.svg'
import Vector1Mobile from 'assets/vectors/footer-vector1-mobile.svg'
import Vector2Mobile from 'assets/vectors/footer-vector2-mobile.svg'
import Logo from 'assets/vectors/footer-logo.svg'
import LogoMobile from 'assets/vectors/footer-logo-mobile.svg'
import Whats from 'assets/vectors/whats-icon.svg'
import Check from 'assets/vectors/alert-positive-icon.svg'
import Facebook from 'assets/vectors/facebook-icon.svg'
import LinkedIn from 'assets/vectors/LinkedIn-icon.svg'
import Instagram from 'assets/vectors/instagram-icon.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 84px;
  padding-top: 84px;
  padding-bottom: 100px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      padding: 40px 38px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 40px 28px 60px;
    }
  `}
`

export const FirstContainer = styled.div`
  position: relative;
  display: flex;
  width: 700px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      flex-direction: column-reverse;
    }
  `}
`

export const SecondContainer = styled.div`
  position: relative;
  width: 480px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      margin-top: 40px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 10px;
    }
  `}
`

export const ContactContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-right: 78px;
  z-index: 1;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-right: 0px;
    }
  `}
`

export const WhatsContainer = styled.a`
  width: 132px;
  height: 136px;
  text-align: center;

  p {
    ${({ theme }) => theme.fonts.smallTextSemiBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }
  span {
    ${({ theme }) => theme.fonts.smallTextBlack};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  &:hover {
    span,
    p {
      color: ${({ theme }) => theme.colors.quaternaryLight};
    }

    svg {
      stop {
        stop-color: ${({ theme }) => theme.colors.quaternaryLight};
      }
    }
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
     display: flex;
     width: 100%;
     height: fit-content;
     flex-direction: row-reverse;
     align-items: center;
     justify-content: flex-end;

     p {
      width: 180px;
      text-align: initial;
     }
    }
  `}
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      align-items: center;
      margin-bottom: 30px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 10px;
    }
  `}
`

export const Address = styled.div`
  margin-bottom: ${({ margin }) => margin};
  p {
    ${({ theme }) => theme.fonts.mediumTextBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
    white-space: pre-line;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      :not(:nth-last-child(1)) {
        margin-bottom: 32px;
      }
      p {
        ${theme.fonts.buttomLink};
      }
    }
  `}
`

export const LegalInfo = styled.div`
  p {
    ${({ theme }) => theme.fonts.smallTextBlack};
    color: ${({ theme }) => theme.colors.backgroundLight};

    :not(:nth-last-child(1)) {
      margin-bottom: 24px;
    }
  }
`

export const Amoeba1 = styled(Vector1)`
  position: absolute;
  top: -82px;
  left: -123px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      top: -94px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const Amoeba2 = styled(Vector2)`
  position: absolute;
  bottom: -143px;
  right: -200px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const Amoeba1Mobile = styled(Vector1Mobile)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: block;
      top: -73px;
      left: -139px;
    }
  `}
`

export const Amoeba2Mobile = styled(Vector2Mobile)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: block;
      bottom: -90px;
      right: -111px;
    }
  `}
`

export const FooterLogo = styled(Logo)`
  margin-bottom: 46px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const FooterLogoMobile = styled(LogoMobile)`
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      margin-bottom: 60px;
    }
  `}
`
export const WhatsIcon = styled(Whats)``

export const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;
  color: ${({ theme }) => theme.colors.backgroundLight};

  label {
    ${({ theme }) => theme.fonts.largeText};
  }

  input {
    width: 476px;
    height: 61px;
    padding: 12px 23px;
    margin-top: 18px;
    margin-bottom: 18px;
    background: ${({ theme }) => theme.colors.primaryLight};
    border: 5px solid ${({ theme }) => theme.colors.primaryDarkest};
    border-radius: 8px;

    ${({ theme }) => theme.fonts.mediumText};
    color: ${({ theme }) => theme.colors.backgroundLight};

    &::placeholder {
      ${({ theme }) => theme.fonts.mediumText};
      color: ${({ theme }) => theme.colors.backgroundLight};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme.colors.primaryLight} inset;
    box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.primaryLight} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.backgroundLight};
    transition: red 5000s ease-in-out 0s;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 500px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      label {
        ${theme.fonts.mediumText};
      }
      input {
        width: 100%;
      }
    }
  `}
`

export const CheckIcon = styled(Check)`
  width: 24px;
  height: 24px;
`

const show = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
  `

export const FeedbackContainer = styled.div`
  ${({ showFeedback }) => (showFeedback ? 'display: flex' : 'display: none')};
  align-items: center;
  animation: ${show} 5s ease forwards;
  margin-top: 10px;
  margin-left: 10px;

  span {
    ${({ theme }) => theme.fonts.smallTextSemiBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-left: 5px;
  }
`

export const SendContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const SocialMediaLink = styled.a`
  width: 41px;
  height: 41px;
`

export const FaceBookIcon = styled(Facebook)``
export const LinkedInIcon = styled(LinkedIn)``
export const InstagramIcon = styled(Instagram)``
