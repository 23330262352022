import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import Loader from 'components/Loader'

import theme from 'styles/theme'
import GlobalStyles from 'styles/GlobalStyles'

import { Wrapper } from './style'

const Layout = ({ children, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => setTimeout(() => setIsLoading(false), 1800), [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Navbar {...props} />
      <Loader hide={!isLoading} />
      <Wrapper isLoading={isLoading}>{children}</Wrapper>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
