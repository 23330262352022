import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${({ isLoading }) => isLoading && 'height: 1200px'};

  ${({ theme, isLoading }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${isLoading && 'height: 700px'};
    }
  `}
`
