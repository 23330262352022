import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      footer: allContentfulRodape {
        nodes {
          phone: telefone
          whatsapp
          eMail
          address: endereco {
            address: endereco
          }
        }
      }
    }
  `)
