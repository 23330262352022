import React, { useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

import Button from 'components/Button'

import emailService from 'services/sendEmail'
import useFooter from 'graphql/useFooter'

import {
  Container,
  Amoeba1,
  Amoeba2,
  FooterLogo,
  FirstContainer,
  SecondContainer,
  ContactContainer,
  AddressContainer,
  Address,
  LegalInfo,
  WhatsIcon,
  WhatsContainer,
  NewsletterContainer,
  Amoeba1Mobile,
  Amoeba2Mobile,
  FooterLogoMobile,
  FeedbackContainer,
  CheckIcon,
  SendContainer,
  SocialMediaContainer,
  SocialMediaLink,
  FaceBookIcon,
  LinkedInIcon,
  InstagramIcon,
} from './style'

const Footer = () => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)

  const data = useFooter().footer.nodes[0]

  const handleSubmit = async () => {
    if (!isEmpty(email) && isEmail(email)) {
      setIsLoading(true)
      try {
        await emailService.sendEmail({
          email,
          isNewsletter: true,
        })
      } catch (e) {
        console.log(e) // eslint-disable-line no-eval
      } finally {
        setTimeout(() => {
          setEmail('')
          setIsLoading(false)
          setShowFeedback(true)
        }, 1000)
        setTimeout(() => {
          setShowFeedback(false)
        }, 5000)
      }
    }
  }

  return (
    <Container>
      <FirstContainer>
        <Amoeba1Mobile />
        <Amoeba1 />
        <ContactContainer>
          <SocialMediaContainer>
            <SocialMediaLink
              href="https://www.linkedin.com/in/amob-associação-moebius-do-brasil-7ba12616/?originalSubdomain=br
"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://www.instagram.com/amobassociacaomoebius/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://pt-br.facebook.com/amob.moebius"
              target="_blank"
              rel="noreferrer"
            >
              <FaceBookIcon />
            </SocialMediaLink>
          </SocialMediaContainer>
          <WhatsContainer
            href={`https://api.whatsapp.com/send?phone=55${data.whatsapp}`}
          >
            <p>
              Clique aqui para conversar no <span>Whatsapp</span>
            </p>
            <WhatsIcon />
          </WhatsContainer>
        </ContactContainer>
        <AddressContainer>
          <FooterLogo />
          <FooterLogoMobile />
          <Address margin="42px">
            <p>Telefone: + 55 {data.phone}</p>
          </Address>
          <Address margin="32px">
            <p> E-mail: {data.eMail}</p>
          </Address>
          <Address>
            <p>{data.address.address}</p>
          </Address>
        </AddressContainer>
      </FirstContainer>
      <SecondContainer>
        <Amoeba2 />
        <Amoeba2Mobile />
        <NewsletterContainer>
          <label htmlFor="email">
            Receba nossa newsletter
            <input
              type="text"
              id="email"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <SendContainer>
            <Button
              secundary
              onClick={() => handleSubmit()}
              showLoader={isLoading}
            >
              Enviar
            </Button>
            <FeedbackContainer showFeedback={showFeedback}>
              <CheckIcon />
              <span>E-mail cadastrado. Entramos em contato em breve</span>
            </FeedbackContainer>
          </SendContainer>
        </NewsletterContainer>
        <LegalInfo>
          <p>Fotos: Compartilhando Sorrisos</p>
          <p>
            Copyright © 2021 AMoB - <br /> Todos os direitos reservados
          </p>
          <p>Desenvolvido por Trinca</p>
        </LegalInfo>
      </SecondContainer>
    </Container>
  )
}

export default Footer
