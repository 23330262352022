import styled from 'styled-components'

export const Container = styled.button`
  display: flex;
  width: fit-content;
  padding: 25px 100px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: none;
  background: ${({ theme }) => theme.colors.primaryDarkest};
  cursor: pointer;
  z-index: 9;

  ${({ theme }) => theme.fonts.largeBold};
  color: ${({ theme }) => theme.colors.backgroundLight};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.neutralRegular};
  }

  ${({ theme, secundary }) =>
    !secundary &&
    `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.buttomLink};
      padding: 25px 56px; 
    }
  `}

  ${({ theme, dark }) =>
    dark &&
    `
      background: ${theme.colors.tertiaryDarkest};
      &:hover {
        background: ${theme.colors.tertiaryMedium};
      }
      &:disabled {
        background: ${theme.colors.quaternaryDarkest};
      }
  `}

  ${({ theme, small }) =>
    small &&
    `
      width: fit-content;
      ${theme.fonts.buttomLink};
      padding: 25px 56px; 
   `}

  ${({ theme, secundary }) =>
    secundary &&
    `
    ${theme.fonts.mediumText};
      width: fit-content;
      padding: 13px 28px;
      border-radius: 8px;
    `}
`

export const LottieContainer = styled.div`
  height: 18px;
  margin-left: 5px;
`
