import { createGlobalStyle } from 'styled-components'
import mediumFontUrl from 'assets/fonts/Montserrat-Medium.ttf'
import semiBoldFontUrlFontUrl from 'assets/fonts/Montserrat-SemiBold.ttf'
import boldFontUrl from 'assets/fonts/Montserrat-Bold.ttf'
import extraBoldFontUrl from 'assets/fonts/Montserrat-ExtraBold.ttf'
import blackFontUrl from 'assets/fonts/Montserrat-Black.ttf'

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.backgroundLight};
    clear: both;
    margin: 0;
  }

  p {
    font-family: 'Montserrat-Medium';
  }

  @font-face {
    font-family: 'Montserrat-Medium';
    src: url(${mediumFontUrl}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(${semiBoldFontUrlFontUrl}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat-Bold';
    src: url(${boldFontUrl}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url(${extraBoldFontUrl}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat-Black';
    src: url(${blackFontUrl}) format('truetype');
    font-display: swap;
  }

  h1 {
    ${({ theme }) => theme.fonts.heading1}
  }
  h2 {
    ${({ theme }) => theme.fonts.heading2}
  }
  
  h3 {
    ${({ theme }) => theme.fonts.heading3}
  }

  h4 {
    ${({ theme }) => theme.fonts.heading4}
  }

`

export default GlobalStyle
