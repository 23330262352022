import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import HamburguerMenu from 'assets/vectors/navbar-mobile-menu.svg'
import MobileSelected from 'assets/vectors/menu-mobile-selected.svg'
import { motion } from 'framer-motion'
// eslint-disable-next-line
import { useLocation } from '@reach/router'

import {
  Container,
  LogoContainer,
  LinksContainer,
  LinkContainer,
  AmoebLogo,
  LightMenuHover,
  DarkMenuHover,
  MobileMenuWrapper,
  MobileMenu,
  MobileRow,
  ArrowClose,
} from './style'

const Navbar = ({ theme, ...props }) => {
  const links = [
    {
      text: (
        <p>
          Quem
          <br />
          somos
        </p>
      ),
      path: '/quem-somos',
      id: 1,
      selectedColor: theme.colors.quaternaryLight,
    },
    {
      text: (
        <p>
          O que é<br />
          Moebius?
        </p>
      ),
      path: '/entendendo-moebius',
      id: 2,
      selectedColor: theme.colors.primaryDarkest,
    },
    {
      text: (
        <p>
          Tratamentos
          <br />
          Disponíveis
        </p>
      ),
      path: '/tratamentos-disponiveis',
      id: 3,
      selectedColor: theme.colors.quaternaryMedium,
    },
    {
      text: (
        <p>
          Nossas
          <br />
          Histórias
        </p>
      ),
      path: '/nossas-historias',
      id: 4,
      selectedColor: theme.colors.primaryDarkest,
    },
    {
      text: (
        <p>
          Instituições
          <br />e Clínicas
        </p>
      ),
      path: '/instituicoes-e-clinicas',
      id: 5,
      selectedColor: theme.colors.primaryDarkest,
    },
  ]

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.tablet})`,
  })
  const [selected, setSelected] = useState()
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const location = useLocation()

  useEffect(() => {
    links.map((link) => location.pathname === link.path && setSelected(link.id))
  }, [location.pathname, links])

  const handleClick = (link) => {
    setSelected(link.id)
  }

  return (
    <Container {...props} css={{ background: props.backgroundColor }}>
      <LogoContainer as={Link} to="/" aria-label="Voltar para página inicial">
        <AmoebLogo />
      </LogoContainer>
      {typeof window !== `undefined` && isMobile && (
        <MobileMenuWrapper onClick={() => setOpenMobileMenu(true)}>
          <HamburguerMenu />
        </MobileMenuWrapper>
      )}
      {typeof window !== `undefined` && !isMobile && (
        <LinksContainer>
          {links.map((link) => (
            <LinkContainer
              {...props}
              key={link.id}
              onClick={() => handleClick(link)}
              css={
                link.id === selected
                  ? { color: link.selectedColor }
                  : { color: theme.colors.backgroundLight }
              }
              as={Link}
              to={link.path}
            >
              {props.isPurple ? <DarkMenuHover /> : <LightMenuHover />}
              {link.text}
            </LinkContainer>
          ))}
        </LinksContainer>
      )}
      {openMobileMenu && (
        <MobileMenu
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          as={motion.div}
          positionTransition
        >
          <ArrowClose onClick={() => setOpenMobileMenu(false)} />
          {links.map((link) => (
            <MobileRow
              {...props}
              key={link.id}
              onClick={() => handleClick(link)}
              css={
                link.id === selected
                  ? { color: theme.colors.primaryDarkest }
                  : { color: theme.colors.neutralDarkest }
              }
              selected={link.id === selected}
              selectedColor={link.selectedColor}
              as={Link}
              to={link.path}
            >
              <MobileSelected />
              {link.text}
            </MobileRow>
          ))}
        </MobileMenu>
      )}
    </Container>
  )
}

export default withTheme(Navbar)
