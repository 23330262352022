import styled from 'styled-components'
import Logo from 'assets/vectors/navbar-desktop-logo.svg'
import MenuHover from 'assets/vectors/menu-hover.svg'
import MenuHoverDark from 'assets/vectors/menu-hover-dark.svg'
import Arrow from 'assets/vectors/arrow-head.svg'

export const LightMenuHover = styled(MenuHover)``
export const DarkMenuHover = styled(MenuHoverDark)``

export const AmoebLogo = styled(Logo)``

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 37px 42px 37px 50px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 28px 37px 0px 28px;
    }
  `}
`

export const LogoContainer = styled.div``

export const LinksContainer = styled.div`
  display: flex;
`

export const LinkContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: 80px;

  svg {
    display: none;
    position: absolute;
    left: 0;
  }
  &:hover {
    svg {
      display: flex;
      fill: red;
    }
  }

  p {
    ${({ theme }) => theme.fonts.mediumTextBold}
    z-index: 2;
  }

  @media (max-width: 1420px) {
    margin-left: 60px;
  }
`

export const MobileMenuWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`

export const MobileMenu = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 48px 42px 42px 55px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  z-index: 5;
`

export const MobileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-right: 13px;
  background: transparent !important;

  svg {
    visibility: ${({ selected }) => !selected && 'hidden'};
  }

  p {
    max-width: 201px;
    text-align: center;
    transform: translateX(-20px);
    ${({ theme, selected }) =>
      selected ? theme.fonts.largeBold : theme.fonts.largeText};
  }
`

export const ArrowClose = styled(Arrow)`
  position: absolute;
  right: 82px;
  top: 48px;
  z-index: 7;
`
