import React from 'react'
import Lottie from 'react-lottie'
import * as loader from 'assets/animations/button-loader.json'

import { Container, LottieContainer } from './style'

const Button = ({ children, onClick, showLoader, ...props }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  }

  return (
    <Container onClick={onClick} {...props}>
      {children}
      {showLoader && (
        <LottieContainer>
          <Lottie options={lottieOptions} />
        </LottieContainer>
      )}
    </Container>
  )
}
export default Button
