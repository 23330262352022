import { css } from 'styled-components'

const heading1 = css`
  font-family: 'Montserrat-Black';
  font-size: 80px;
  line-height: 98px;
`

const heading2 = css`
  font-family: 'Montserrat-Bold';
  font-size: 64px;
  line-height: 78px;
`

const heading3 = css`
  font-family: 'Montserrat-ExtraBold';
  font-size: 36px;
  line-height: 44px;
`

const heading4 = css`
  font-family: 'Montserrat-Black';
  font-size: 24px;
  line-height: 29px;
`

const largeText = css`
  font-family: 'Montserrat-Medium';
  font-size: 24px;
  line-height: 29px;
`

const largeBold = css`
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  line-height: 29px;
`

const mediumText = css`
  font-family: 'Montserrat-Medium';
  font-size: 20px;
  line-height: 25px;
`

const mediumTextBold = css`
  font-family: 'Montserrat-Bold';
  font-size: 20px;
  line-height: 25px;
`

const mediumTextExtraBold = css`
  font-family: 'Montserrat-ExtraBold';
  font-size: 20px;
  line-height: 25px;
`

const mediumTextBlack = css`
  font-family: 'Montserrat-Black';
  font-size: 20px;
  line-height: 25px;
`

const smallTextMedium = css`
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  line-height: 15px;
`

const smallTextSemiBold = css`
  font-family: 'Montserrat-Semibold';
  font-size: 12px;
  line-height: 15px;
`

const smallTextBlack = css`
  font-family: 'Montserrat-Black';
  font-size: 12px;
  line-height: 15px;
`

const subtitle = css`
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
  line-height: 20px;
`

const buttomLink = css`
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  line-height: 20px;
`

const theme = {
  colors: {
    primaryDarkest: '#6443C1',
    primaryMedium: '#7F62D0',
    primaryLight: '#AC96EB',
    secondaryDarkest: '#DB626E',
    secondaryMedium: '#E97782',
    secondaryLight: '#FC949E',
    tertiaryDarkest: '#30B0B0',
    tertiaryMedium: '#40C1C1',
    tertiaryLight: '#9ED3D3',
    quaternaryDarkest: '#E5A873',
    quaternaryMedium: '#F2B581',
    quaternaryLight: '#F9CBA5',
    neutralDarkest: '#4C4C4C',
    neutralMedium: '#828282',
    neutralRegular: '#BDBDBD',
    neutralLight: '#E0E0E0',
    backgroundMedium: '#EDDBCB',
    backgroundLight: '#FFFFFF',
    error: '#ED3B48',
  },
  fonts: {
    heading1,
    heading2,
    heading3,
    heading4,
    largeText,
    largeBold,
    mediumText,
    mediumTextBold,
    mediumTextBlack,
    smallTextMedium,
    smallTextBlack,
    subtitle,
    buttomLink,
    smallTextSemiBold,
    mediumTextExtraBold,
  },
  breakPoints: {
    smallestMobile: '320px',
    smallMobile: '360px',
    mediumMobile: '375px',
    bigMobile: '412px',
    biggestMobile: '500px',
    tablet: '1024px',
    smallTablet: '768px',
  },
}

export default theme
