import React from 'react'
import Lottie from 'react-lottie'
import loader from 'assets/animations/smile-loader.json'

import { Container, LottieContainer } from './style'

const Loader = ({ hide }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  }

  return (
    <Container hide={hide}>
      {!hide && (
        <LottieContainer>
          <Lottie options={lottieOptions} />
        </LottieContainer>
      )}
    </Container>
  )
}

export default Loader
