import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 1200px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  z-index: 99;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 700px;
    }
  `}
`

export const LottieContainer = styled.div`
  margin-top: 160px;
  height: 522px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 30px;
    }
  `}
`
